@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap');

:root {
  --dot-color: rgba(51, 51, 51, 0.295);
}

* {
  font-family: 'Josefin Sans', sans-serif;
}

*:focus {
  outline: none;
}

body {
  background-color: rgb(255, 255, 255);
}

.App {
  text-align: center;
}

input {
  margin: 5px 0px 5px 0px;
}

.loginBox {
  display: inline-block;
  margin: 2px;
}

.upcoming-freeze {
  opacity: 0.1;
}

footer {
  background-color: rgb(255, 255, 255);
  color: white;
  padding: 10px;
  text-align: center;
  font-size: 14px;
}

div.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

div.loading {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

div.loading:before {
  content: '';
  height: 100%;
  width: 100%;
  border: 2px solid transparent;
  border-color: transparent #FFCB05 transparent #C6168D;
  border-radius: 50%;
  animation: rotate-loading 1.5s linear 0s infinite normal;
}

div.loading:hover:before {
  border-color: transparent #E45635 transparent #E45635;
}

#loading-text {
  color: #3498db; /* Change to blue color */
  font-family: "Helvetica Neue, Helvetica, Arial";
  font-size: 10px;
  font-weight: bold;
  opacity: 1; /* Set opacity to 1 for immediate visibility */
  text-align: center;
  text-transform: uppercase;
  width: 100px;
  margin-top: 10px; /* Adjusted margin for better positioning */
}

@keyframes rotate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* ... Other styles for your page ... */
